import type { HTMLAttributes } from 'react';
import React from 'react';
import { Button, DesignType } from '$src-components/atoms/Button';
import { trimSpacesFromString } from '$util/stringOperations/trimSpacesFromString';

export interface CallUsButtonProps extends HTMLAttributes<HTMLDivElement> {
  readonly text: string;
  readonly areaCode: string;
  readonly phoneNumber: string;
  readonly onClick?: () => void;
}

export function CallUsButton({ text, areaCode, phoneNumber, onClick }: CallUsButtonProps): JSX.Element {
  return (
    <Button
      designType={DesignType.PRIMARY}
      href={`tel:${areaCode}${trimSpacesFromString(phoneNumber)}`}
      iconId="communication/phone"
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

CallUsButton.displayName = 'CallUsButton';
