import type { HTMLAttributes } from 'react';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { showLiveChat } from '$components/LiveChat';
import { Button, DesignType } from '$src-components/atoms/Button';
// import Grid from '$components/layout/Grid';

import { Col } from '../../../src/components/atoms/Grid';
import styles from './index.module.scss';

export interface ChatWithUsProps extends HTMLAttributes<HTMLDivElement> {
  readonly title: string;
  readonly onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function ChatWithUs({ title, onClick }: ChatWithUsProps): JSX.Element {
  const onClickAction = useCallback(
    (e: any) => {
      showLiveChat();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );
  return (
    <Col className={classNames(ChatWithUs.displayName, styles.Wrapper)}>
      <Button
        designType={DesignType.SECONDARY_DARK}
        onClick={onClickAction}
        iconId="communication/message-chat-circle"
      >
        {title}
      </Button>
    </Col>
  );
}

ChatWithUs.displayName = 'ChatWithUs';
