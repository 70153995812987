import classNames from 'classnames';
import { withResponsive } from '$util/theme';
import { Title } from '@/components/atoms/Title';

const ResponsiveTitle = withResponsive(Title);

export interface SectionTitleProps {
  readonly desktop: React.ReactNode;
  readonly mobile: React.ReactNode;
  readonly className?: string;
}

// @deprecated implement using useResizeViewport instead
export function SectionTitle({ desktop, mobile, className, ...props }: SectionTitleProps): JSX.Element {
  return (
    <ResponsiveTitle className={classNames(SectionTitle.displayName, className)} {...props}>
      {desktop}
    </ResponsiveTitle>
  );
}

SectionTitle.displayName = 'SectionTitle';
