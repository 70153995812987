import { internalAnalytics } from '$util/analytics/analyticsService/client';
import { AnalyticsServiceFieldKey } from '$util/analytics/analyticsService/types';
import { googleAnalytics4 } from '$util/analytics/googleAnalytics4';

export const sendInternalAnalytic = (fieldKey: AnalyticsServiceFieldKey) => {
  internalAnalytics.sendTeamSupportSectionEvents(fieldKey);
  switch (fieldKey) {
    case AnalyticsServiceFieldKey.TEAM_SUPPORT_SECTION_PHONE_CLICKED:
      googleAnalytics4.needHelpModalCallUsClicked(null);
      break;
    case AnalyticsServiceFieldKey.TEAM_SUPPORT_SECTION_LIVE_CHAT_CLICKED:
      googleAnalytics4.needHelpModalLiveChatClicked(null);
      break;
    case AnalyticsServiceFieldKey.TEAM_SUPPORT_SECTION_CONTACT_FORM_CLICKED:
      googleAnalytics4.needHelpModalContactFormClicked(null);
      break;
    case AnalyticsServiceFieldKey.TEAM_SUPPORT_SECTION_EMAIL_CLICKED:
      googleAnalytics4.needHelpModalEmailUsClicked(null);
      break;
    default:
      break;
  }
};
