import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';
import Grid from '$components/layout/Grid';
import { Button, DesignType } from '$src-components/atoms/Button';

import styles from './index.module.scss';

const { Col } = Grid;
export interface ContactUsProps extends HTMLAttributes<HTMLDivElement> {
  readonly title: string;
  readonly formLink: string;
}

export function ContactUs({ title, formLink }: ContactUsProps): JSX.Element {
  return (
    <Col span={6} className={classNames(ContactUs.displayName, styles.component)}>
      <Button
        designType={DesignType.SECONDARY_DARK}
        href={formLink}
        target="_blank"
        iconId="communication/mail-04"
      >
        {title}
      </Button>
    </Col>
  );
}

ContactUs.displayName = 'ContactUs';
