import type { HTMLAttributes } from 'react';
import React from 'react';
import { Button, DesignType } from '$src-components/atoms/Button';
import { Col } from '$src-components/atoms/Grid';
import styles from './index.module.scss';

export interface EmailUsProps extends HTMLAttributes<HTMLDivElement> {
  readonly title: string;
  readonly email: string;
  readonly onClick?: () => void;
}

export function EmailUs({ title, email, onClick }: EmailUsProps): JSX.Element {
  return (
    <Col small={6} className={styles.emailUsCol}>
      <Button
        designType={DesignType.SECONDARY_DARK}
        href={`mailto:${email}`}
        iconId="communication/mail-04"
        onClick={onClick}
      >
        {title}
      </Button>
    </Col>
  );
}

EmailUs.displayName = 'EmailUs';
