import styled from 'styled-components';
import Grid from '$components/layout/Grid';
import { vars, pxToRem, media } from '$util/theme';

export const Styled = styled(Grid)`
  p {
    margin-bottom: 0;
  }
  .TeamSupport__content {
    .TeamSupport__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      h3.title,
      h3.title p {
        text-align: center;
        font-size: ${pxToRem(28)};
        font-weight: ${vars.fontWeight.semiBold};
        line-height: ${pxToRem(32)};

        & > .wave,
        & > .wave > span {
          width: 35px !important;
          height: 35px !important;
        }
      }
    }
    .TeamSupport__text {
      display: flex;
      font-size: ${pxToRem(18)};
      line-height: ${pxToRem(28)};
      max-width: 600px;
      margin-bottom: 10px;
      > p {
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(28)};
        text-align: center;
        ${media.down('sm')} {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .TeamSupport__CTA-group {
    display: flex;

    margin-top: 20px;
    margin-bottom: 20px;

    column-gap: 40px;

    justify-content: center;
    align-items: center;

    ${media.up('md')} {
      row-gap: 20px;
    }

    ${media.down('md')} {
      flex-direction: column;
    }

    .TeamSupport__CTA-call-us {
      display: flex;
      ${media.down('md')} {
        width: 90%;
      }
      justify-content: center;
      .Button {
        width: 90%;
        justify-content: center;
        ${media.up('md')} {
          width: auto;
        }
        ${media.only('md')} {
          max-width: 310px;
        }
      }
    }
    .TeamSupport__CTA-email-us {
      display: flex;
      justify-content: space-around;
      column-gap: 20px;
      ${media.down('sm')} {
        margin-top: 30px;
      }
      .EmailUs,
      .ChatWithUs,
      .ContactUs {
        min-width: fit-content;
      }
    }
  }

  &.-modal-context {
    padding: 0;

    .TeamSupport__content {
      .TeamSupport__title {
        h3.title {
          font-size: ${pxToRem(22)};
        }
      }

      .TeamSupport__text {
        .-highlight {
          display: none;
        }
      }
    }

    .TeamSupport__CTA-group {
      flex-direction: column;
      .TeamSupport__CTA-call-us {
        width: 100%;
        .Button {
          width: 100%;
        }
      }
    }

    .ChatWithUs.-desktop {
      justify-content: flex-start;
    }
  }
`;
